.container1 {
    width: 100%;
    height: 100%;
    margin-top: 40%;
    justify-content: center;
    align-items: center;
    background: #F8BD26; 
    background: linear-gradient(
      135deg,
      #121212 25%,
      #1a1a1a 25%,
      #1a1a1a 50%,
      #121212 50%,
      #121212 75%,
      #1a1a1a 75%,
      #1a1a1a
    );
    background-size: 40px 40px;
    animation: move 4s linear infinite;
  }
  
  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 40px 40px;
    }
  }

.footer-heading{
    margin-top: -27%;
}
.footer-div{
    background:transparent;
}
.footer-div h1{
    text-align: center;
    color: #ffffff;
}
.footer-h1{
    background: transparent; 
    text-align:justify;
    color: #ffffff;
}
.footer-link{
    background: transparent;
    padding: 30px;
    color: #ffffff;
}
.link-h{
    background: transparent;
    margin-top: 60px;
    margin-bottom: 20px;
    padding-left: 120px;
    color: #ffffff;
}
.link-hs{
    padding-left: 0px;
}
.link-ul{
    background: transparent;
    padding-left: 130px;
    list-style: none;
}
.link-ul li{
    background: transparent;
    padding: 5px;
    color: #ffffff;
}
.link-span{
    background: transparent;
    margin: 40px 0px;
    color: #ffffff;
    /* color: black */
}
.link-i{
    margin: 40px 0px;
    /* color: black */
}
.link-support{
    background: transparent;
    margin: 30px 0px; 
}
.social-link{
    background: transparent;
}
.social-span{
    background: transparent;
    margin: 20px;
}
.bi{
    background: transparent;
    color: #ffffff;
}
.copyright{
    margin-top: 10px;
    text-align: end;
    background: transparent;
    color: #ffffff;
}

@media (min-width:769px) and (max-width:1200px) {
  
    /* .wave img{
       margin-bottom: -190px;
    } */
    .footer-heading{
        margin-top: -27%;
    }
    .footer-div{
        background:transparent;
    }
    .footer-img{
        margin-left: -50px;
    }
    .footer-p{
        background: transparent; 
        text-align:justify;
        margin-top: 160px;
    }
    .footer-link{
        background: transparent;
        padding-top: 30px;
    }
    .link-h{
        background: transparent;
        margin-top: 60px;
        margin-bottom: 60px;
        padding-left: 60px;
    }
    .link-ul{
        background: transparent;
        padding-left: 60px;
        list-style: none;
    }
    .link-ul li{
        background: transparent;
        padding: 5px;
    }
    .link-span{
        background: transparent;
        /* margin-right: 20px; */
    }
    .link-i{
        margin: 40px 0px;
        /* color: black */
    }
    .link-hs{
        padding-left: 0px;
    }
    .link-support{
        background: transparent;
        margin: 10px 0px; 
        margin-left: 10px;
    }
    .social-link{
        background: transparent;
    }
    .social-span{
        background: transparent;
        margin: 20px;
    }
    .bi{
        background: transparent;
        color: bisque;
    }
}

@media (min-width:500px) and (max-width: 768px) {
    /* .wave img{
       margin-bottom: -100%
    } */
    .footer-heading{
        margin-top: -20%;
    }
    .footer-div{
        background:transparent;
    }
    .footer-img{
        /* margin-top: -125%; */
        margin-left: 50px;
    }
    .footer-p{
        background: transparent; 
        text-align:justify;
        margin-top: 0px;
    }
    .footer-link{
        background: transparent;
        padding-top: 10px;
    }
    .support-sec{
        /* position: absolute; */
        margin-left: 150px;
    }
    .link-h{
        background: transparent;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 0px;
    }
    .link-ul{
        background: transparent;
        padding-left: 0px;
        list-style: none;
    }
    .link-ul li{
        background: transparent;
        padding: 2px;
    }
    .link-span{
        background: transparent;
        /* margin-right: 20px; */
    }
    .link-i{
        margin: 0px 10px;
        /* color: black */
    }
    .link-hs{
        padding-left: 10px;
        margin-top: -230px;
    }
    .link-support{
        background: transparent;
        margin: 0px 0px; 
        margin-left: 0px;
    }
    .social-link{
        background: transparent;
        margin-bottom: 20px;
    }
    .social-span{
        background: transparent;
        margin: 20px;
    }
    .bi{
        background: transparent;
        color: bisque;
    }
    /* .link-spangeo {
        width: fit-content;
        text-align:center;

    } */
}

@media (max-width: 499px) {
    /* .wave img{
       margin-bottom: -170%
    } */
    .footer-heading{
        margin-top: -20%;
    }
    .footer-div{
        background:transparent;
    }
    .footer-img{
        /* margin-top: -200%; */
        margin-left: 30px;
    }
    .footer-p{
        background: transparent; 
        text-align:justify;
        margin-top: 0px;
    }
    .footer-link{
        background: transparent;
        padding-top: 10px;
    }
    .support-sec{
        /* position: absolute; */
        margin-left: 150px;
    }
    .link-h{
        background: transparent;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 0px;
    }
    .link-ul{
        background: transparent;
        padding-left: 0px;
        list-style: none;
    }
    .link-ul li{
        background: transparent;
        padding: 2px;
    }
    .link-span{
        background: transparent;
        /* margin-right: 20px; */
    }
    .link-i{
        margin: 0px 10px;
        /* color: black */
    }
    .link-hs{
        padding-left: 10px;
        margin-top: -230px;
    }
    .link-support{
        background: transparent;
        margin: 0px 0px; 
        margin-left: 0px;
    }
    .social-link{
        background: transparent;
        margin-bottom: 20px;
    }
    .social-span{
        background: transparent;
        margin: 20px;
    }
    .bi{
        background: transparent;
        color: bisque;
    }
    .link-spangeo{
       font-size: small;
       margin-left: -130px;
    }
}


  