.specialH1{
   position: absolute;
   left: 0px;
   top: 850px;
}
.e-card {
    margin: 800px auto;
    background: transparent;
    box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
    position: relative;
    width: 350px;
    height: 420px;
    border-radius: 16px;
    overflow: hidden;
  }
  .e-card1{
    top: -180%;
    margin: 0 auto;
    width: 350px;
    background: transparent;
    box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
    position: relative;
    height: 420px;
    border-radius: 16px;
    overflow: hidden;
  }
  .wave {
    position: absolute;
    width: 540px;
    height: 700px;
    opacity: 0.6;
    left: 0;
    top: 0;
    margin-left: -50%;
    margin-top: -70%;
    background: linear-gradient(744deg,#af40ff,#F8BD28 60%,#00ddeb);
  }
  
  .icon {
    width: 3em;
    margin-top: -1em;
    padding-bottom: 1em;
  }
  
  .infotop {
    text-align: center;
    font-size: 20px;
    position: absolute;
    top: 1.5em;
    left: 0;
    right: 0;
    /* color: rgb(255, 255, 255); */
    font-weight: 600;
  }
  .infotop h1{
    font-size: 60px;
  }
 .name {
    font-size: 85%;
    font-weight: 100;
    position: relative;
    top: 1.0em;
    /* text-transform: lowercase; */
    text-align: justify;
    margin: auto 15px;
    /* color: #ffffff; */
  }
  
  .wave:nth-child(2),
  .wave:nth-child(3) {
    top: 210px;
  }
  
  .playing .wave {
    border-radius: 40%;
    animation: wave 3000ms infinite linear;
  }
  
  .wave {
    border-radius: 40%;
    animation: wave 55s infinite linear;
  }
  
  .playing .wave:nth-child(2) {
    animation-duration: 4000ms;
  }
  
  .wave:nth-child(2) {
    animation-duration: 50s;
  }
  
  .playing .wave:nth-child(3) {
    animation-duration: 5000ms;
  }
  
  .wave:nth-child(3) {
    animation-duration: 45s;
  }
  
  @keyframes wave {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

@media (min-width:769px) and (max-width:1200px) {
  .specialH1{
    top: 80%;
    padding-top: 50px;
 }
 .e-card {
  margin: 850px auto;
  width: 100%;
  height: 500px;
}
.e-card1{
  margin: -40px auto;
  width: 100%;
  height: 500px;
}
}

@media (max-width: 768px) {
  .specify{
    margin-bottom:1350px;
  }
  .specialH1{
    position: absolute;
    left: 0px;
    top: 1000px;
 }
 .e-card {
     /* margin: 800px auto; */
     margin-bottom: -950px;
     margin-top: 1000px;
     background: transparent;
     box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
     position: relative;
     top: 0%;
     width: 100%;
     height: 420px;
     border-radius: 16px;
     overflow: hidden;
     line-height: 25px;
   }
   .e-card1{
    line-height: 22px;
     top: -10%;
     /* margin: 0 auto; */
     margin-bottom: -950px;
     margin-top: 1000px;
     width: 100%;
     background: transparent;
     box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
     position: relative;
     height: 420px;
     border-radius: 16px;
     overflow: hidden;
   }
   
}