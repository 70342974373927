.about{
    /* margin: 120px; */
    margin-top:-600px;
    /* margin-bottom: 280px; */
    /* font-family: "Roboto", sans-serif; */
}

.aboutH1{
    margin-bottom: 80px;
    margin-top: 30px;
    /* font-family: "Roboto", sans-serif; */
    z-index: 9;
    /* padding-top: 20px; */
}
.aboutp{
  text-align:justify;
  font-size: 20px;
  /* font-family: "Roboto", sans-serif; */
}
.tank-img{
    background: #F8BD28;
    margin-top: -120px;
   
    object-fit: cover;
    animation: morph 3.5s linear infinite;
}
@keyframes morph {
    0%,100%{
        border-radius: 40% 60% 705 30% / 40% 40% 60% 50%;
    }
    34%{
        border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
    }
    67%{
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    }
    
}

@media (max-width: 768px) {
    .about{
        margin-top:-300px;
    }
}
