div.value{
    background: linear-gradient(to top, #21212000, rgb(159, 152, 112)), url(../../assets/banner4.jpg) no-repeat 80% 80%/cover;
    /* width: 100%; */
    /* height: 550px; */
    /* position: fixed; */
    top: 90%;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
}
.value-container{
    margin-top: 10%;
    height:fit-content;
    padding: 90px 0px;
    text-size-adjust: 100%;
}
.aboutH2{
    margin-bottom: 5%;
    color: #ffffff;
}
.value-card{
    margin-bottom: 20px;
   color: #ffffff;
} 
.value-card p{
    text-align: justify;
    margin: 15px;
    color: #ffffff;
    text-size-adjust: 100%;
}
.value-card h2{
    color: #ffffff;
}
.value-card1{
    /* width: 150px; */
    text-align: center;
    box-shadow: -10px 18px 200px -9px #ffffff;
    border-radius: 26px;
    width: 100%;
    height: 100%;
    margin: 20px;
    background: #000000;
    opacity: 0.75;
}
/* .value-card1:hover{ */
    /* box-shadow: -10px 18px 200px -9px #ffffff; */
    /* border-radius: 50%; */
    /* font-size: 14px; */
    /* background: #F8BD28;
    opacity: 0.75;
    color: black; */
/* } */