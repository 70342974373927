@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Dancing+Script&family=Lato&family=Lobster&display=swap');

body {
  margin: 0;
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato";
  color: #000;
  /* font-family: "Great Vibes", cursive; */
}
