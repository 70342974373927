/* $ animationSpeed: 40s; */
.h-client{
	color: #F8BD26;
	font-weight: 500;
	text-transform: uppercase;
	text-shadow: 5px 5px 5px #7a7566;
	font-size: 70px;
	padding-top: 13%;
}
.cen-client{
	top: -60px;
	margin: auto 30px;
	background: url(../../assets/clientbg.jpg);
	background-size:contain;
	background-position: center;
	position: relative;
   z-index: -2;
   margin-left: -90px;
   width: 120%;
	height: 530px;
}

@keyframes scroll {
	0% { transform: translateX(calc(100px * 4)); }
	50% { transform: translateX(calc(-100px * 4))}
}

.slider4 {
    align-items: center;
	justify-content: center;
	height: 1100px;
	margin: -60px;
	/* overflow:hidden; */
	position: relative;
    top: 120px;
	width: auto;
}
.slide4 h1{
	text-align: center;
}
.slide4 img{
	margin-top: 30%;
	margin-left: 15%;
}
	
	.slide-track1 {
		animation: 15s scroll linear infinite;
		display: flex;
		width: calc(120px * 14);
	}
	.slide4 {
        /* border: 2px solid gray; */
        border-radius: 100%;
        box-shadow: 19px 10px 13px  10px   #7a7566;
        margin:90px 20px;
		height: 350px;
		width: 350px;
	}

@media (min-width:760px) and (max-width:1200px) {
	.h-client{
		font-weight: 400;
		font-size: 60px;
		padding-top: 22%;
	}
	.cen-client{
		background-size:cover;
		background-position: center;
		position: relative;
		left: 0px;
	   z-index: -2;
	   margin-left: 0px;
	   width: 140%;
		height: 530px;
	}
	.slide-track1 {
		animation: 15s scroll linear infinite;
		display: flex;
		width: calc(70px * 14);
	}
	.slide4 {
        /* border: 2px solid gray; */
        border-radius: 100%;
        box-shadow: 19px 10px 13px  10px   #7a7566;
        margin:90px 20px;
		height: 200px;
		width: 200px;
	}
	.slide4 img{
		width: 150px;
	}
}
	
@media (max-width: 768px) {
	.h-client{
		color: #F8BD26;
		font-weight: 300;
		text-transform: uppercase;
		text-shadow: 5px 5px 5px #7a7566;
		font-size: 40px;
		padding-top: 250px;
	}
	.cen-client{
		top: -60px;
		margin: auto -50px;
		background: url(../../assets/clientbg.jpg);
		background-size:contain;
		background-position: center;
		position: relative;
	   z-index: -2;
	   margin-left: 0px;
	   width: 100%;
		height: 530px;
	}
	.slider4 {
    align-items: center;
	justify-content: center;
	height: 100%;
	margin: -60px ;
	/* overflow:hidden; */
	position: relative;
    top: 120px;
	width: auto;
}
.slide4 h1{
	text-align: center;
}
.slide4 img{
	margin-top: 30%;
	margin-left: 15%;
}
	
	.slide-track1 {
		animation: 15s scroll linear infinite;
		display: flex;
		width: calc(120px * 14);
	}
	.slide4 {
        /* border: 2px solid gray; */
        border-radius: 100%;
        box-shadow: 19px 10px 13px  10px   #7a7566;
        margin:90px 20px;
		height: 300px;
		width: 350px;
	}
	@keyframes scroll {
		0% { transform: translateX(calc(100px * 1)); }
		50% { transform: translateX(calc(-100px * 1))}
	}
	
}
