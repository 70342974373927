.swiper1 {
  background:aliceblue;
  width: 380px;
  height: 400px;
  position: absolute;
  left: 40%;
  top: 50%;
  margin-left: -100px;
  margin-top: -150px;
  background-color: aliceblue; 
}
.swiper1 .swiper-slide {
  background-position: center;
  background-size: cover;
  margin-top: -80px;
  background-color: aliceblue;
  opacity: 0.999999;
}

.swiper1 .swiper-slide img {
  display: block;
  width: 100%;
  background-color: aliceblue;
  height: 450px;
}

.service-row{
    background: #353535;
    border-radius: 20px;
    box-shadow: 35px 35px 35px gray;
    position: relative;
    padding: 105px;
}
div.ser{
    height: 600px;
    margin-top: 100px;
    background-color: aliceblue;
    position: sticky;
    top: 60px;
    z-index: -9;
}
.stick1{
    position: relative;
    top: 170px;
}

.loader2 {
    display: flex;
    width: 10rem;
    height: 10rem;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    top: 100px;
    left: 45%;
    z-index: 5;
  }
  
  .curve {
    width: 220%;
    height: 220%;
    position: absolute;
    animation: rotate 8s linear infinite;
    fill: transparent;
  }
  
  .curve text {
    letter-spacing: 4px;
    text-transform: uppercase;
    font: 1.5em "Fira Sans", sans-serif;
    fill: #000000;
    filter: drop-shadow(0 2px 8px black);
  }
  
  .blackhole {
    z-index: -1;
    display: flex;
    position: absolute;
    width: 8rem;
    height: 8rem;
    align-items: center;
    justify-content: center;
  }
  
  .blackhole-circle {
    z-index: 0;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: radial-gradient(circle at center, white 25%, black 35%, black 100%);
    box-shadow: 0px 0px 2rem black;
    align-items: center;
    justify-content: center;
  }
  
  .blackhole-circle::after {
    z-index: 0;
    position: absolute;
    content: "";
    display: flex;
    width: 250%;
    height: 250%;
    border-radius: 50%;
    border: 4px solid white;
    background: radial-gradient(circle at center, black 35%, white 60%, white 100%);
    box-shadow: 0px 0px 5rem #c2babb;
    align-items: center;
    justify-content: center;
    filter: blur(4px);
    animation: pulseAnimation linear infinite 2s alternate-reverse;
  }
  
  .blackhole-circle::before {
    z-index: 1;
    content: "";
    display: flex;
    width: 9rem;
    height: 9rem;
    border: 2px solid #ffffff;
    box-shadow: 3px 3px 10px #c2babb, inset 0 0 1rem #ffffff;
    border-radius: 50%;
    top: 5rem;
    filter: blur(0.5px);
    animation: rotate linear infinite 3s;
  }
  
  .blackhole-disc {
    position: absolute;
    z-index: 0;
    display: flex;
    width: 5rem;
    height: 10rem;
    border-radius: 50%;
    background: radial-gradient(circle at center, #ffffff 80%, #353535 90%, white 100%);
    filter: blur(1rem) brightness(130%);
    border: 1rem solid white;
    box-shadow: 0px 0px 3rem #d7c4be;
    transform: rotate3d(1, 1, 1, 220deg);
    animation: pulseAnimation2 linear infinite 2s alternate-reverse;
    justify-content: center;
    align-items: center;
  }
  
  .blackhole-disc::before {
    content: "";
    position: absolute;
    z-index: 0;
    display: flex;
    width: 5rem;
    height: 10rem;
    border-radius: 50%;
    background: radial-gradient(circle at center, #ffffff 80%, #353535 90%, white 100%);
    filter: blur(3rem);
    border: 1rem solid white;
    box-shadow: 0px 0px 6rem #d7c4be;
    animation: pulseAnimation linear infinite 2s alternate-reverse;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulseAnimation {
    0% {
      box-shadow: 0px 0px 3rem #c2babb;
      transform: scale(1);
    }
  
    100% {
      box-shadow: 0px 0px 5rem #c2babb;
      transform: scale(1.09);
    }
  }
  
  @keyframes pulseAnimation2 {
    0% {
      box-shadow: 0px 0px 3rem #c2babb;
      transform: rotate3d(1, 1, 1, 220deg) scale(1);
    }
  
    100% {
      box-shadow: 0px 0px 5rem #c2babb;
      transform: rotate3d(1, 1, 1, 220deg)  scale(.95);
    }
  }

  .ribbon {
    width: 320px;
    position: absolute;
    left: 88%;
    top: 28%;
    margin-left: -160px;
    text-align: center;
    font-size: 25px !important;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background: #F8BD28;
    background: linear-gradient(to bottom, #F8BD28, #F8EF72);
    box-shadow: rgba(0,0,0,0.3) 0 1px 1px;
    font-family: 'Helvetica Neue',Helvetica, sans-serif;
    /* z-index: 999; */
}

.ribbon p {
    font-size: 18px !important;
    color: #FFFFFF;
    text-shadow: #EDC65A 0 1px 0;
    margin:0px;
    padding: 22px 10px;
}

.ribbon:before, .ribbon:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -1em;
    border: 1.5em solid #F8E672;
    z-index: -1;
}

.ribbon:before {
    left: -2em;
    border-right-width: 1.5em;
    border-left-color: transparent;
    box-shadow: rgba(0,0,0,0.4) 1px 1px 1px;
}

.ribbon:after {
    right: -2em;
    border-left-width: 1.5em;
    border-right-color: transparent;
    box-shadow: rgba(0,0,0,0.4) -1px 1px 1px;
}

.ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
    border-color: #F8DE5C transparent transparent transparent;
    position: absolute;
    display: block;
    border-style: solid;
    bottom: -1em;
    content: '';
}

.ribbon .ribbon-content:before {
    left: 0;
    border-width: 1em 0 0 1em;
}

.ribbon .ribbon-content:after {
    right: 0;
    border-width: 1em 1em 0 0;
}

.ribbon-stitches-top {
    margin-top:2px;
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
}

.ribbon-stitches-bottom {
    margin-bottom:2px;
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.3);
}

@media (min-width:1000px) and (max-width:1200px) {
  div.ser{
    height: 700px;
    margin-top: 100px;
    background-color: aliceblue;
    position: sticky;
    top: 60px;
    z-index: -9;
  }
  .stick1{
      position: relative;
      top: 170px;
      height: 100%;
  }
  .ribbon {
    width: 260px;
    position: absolute;
    left: 88%;
    top: 28%;
    margin-left: -160px;
    text-align: center;
    font-size: 25px !important;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background: #F8BD28;
    background: linear-gradient(to bottom, #F8BD28, #F8EF72);
    box-shadow: rgba(0,0,0,0.3) 0 1px 1px;
    font-family: 'Helvetica Neue',Helvetica, sans-serif;
    /* z-index: 999; */
}

.ribbon p {
    font-size: 18px !important;
    color: #FFFFFF;
    text-shadow: #EDC65A 0 1px 0;
    margin:0px;
    padding: 22px 10px;
}
}
@media (min-width:769px) and (max-width:999px) {
  div.ser{
    height: 800px;
    margin-top: 100px;
    background-color: aliceblue;
    position: sticky;
    top: 60px;
    z-index: -9;
  }
  .ribbon {
    width: 200px;
    position: absolute;
    left: 92%;
    top: 15%;
    margin-left: -160px;
    text-align: center;
    font-size: 25px !important;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background: #F8BD28;
    background: linear-gradient(to bottom, #F8BD28, #F8EF72);
    box-shadow: rgba(0,0,0,0.3) 0 1px 1px;
    font-family: 'Helvetica Neue',Helvetica, sans-serif;
    /* z-index: 999; */
}
.swiper1 {
  background:aliceblue;
  width: 200px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -150px;
  background-color: aliceblue; 
}
.swiper1 .swiper-slide {
  background-position: center;
  background-size: cover;
  margin-top: -80px;
  background-color: aliceblue;
  opacity: 0.999999;
}

.swiper1 .swiper-slide img {
  display: block;
  width: 100%;
  background-color: aliceblue;
  height: 300px;
}
}

@media (min-width: 400px) and (max-width: 768px) {
  div.ser{
    height: 900px;
    margin-top: 100px;
    background-color: aliceblue;
    position: sticky;
    top: 60px;
    z-index: -9;
  }
  .ribbon {
    width: 200px;
    position: absolute;
    left: 90%;
    top: 15%;
    margin-left: -160px;
    text-align: center;
    font-size: 20px !important;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background: #F8BD28;
    background: linear-gradient(to bottom, #F8BD28, #F8EF72);
    box-shadow: rgba(0,0,0,0.3) 0 1px 1px;
    font-family: 'Helvetica Neue',Helvetica, sans-serif;
    /* z-index: 999; */
}
.swiper1 {
  background:aliceblue;
  width: 90%;
  height: 500px;
  position: absolute;
  left:30%;
  top: 40%;
  margin-left: -100px;
  margin-top: -150px;
  background-color: aliceblue; 
}
.swiper1 .swiper-slide img {
  display: block;
  width: 100%;
  background-color: aliceblue;
  height: 500px;
}
.loader2 {
  display: flex;
  width: 10rem;
  height: 10rem;
  top: 170px;
  left: 20%;
  z-index: 5;
}

.curve {
  width: 160%;
  height: 160%;
  position: absolute;
  animation: rotate 8s linear infinite;
  fill: transparent;
}
.blackhole {
  z-index: -1;
  display: flex;
  position: absolute;
  width: 6rem;
  height: 6rem;
  align-items: center;
  justify-content: center;
}
}
@media (min-width: 200px) and (max-width: 399px) {
  div.ser{
    height: 1200px;
    margin-top: 40px;
    background-color: aliceblue;
    position: sticky;
    top: -200px;
    z-index: -9;
  }
  .ribbon {
    width: 200px;
    position: absolute;
    /* left: 90%; */
    top: 10%;
    margin-left: -160px;
    text-align: center;
    font-size: 15px !important;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background: #F8BD28;
    background: linear-gradient(to bottom, #F8BD28, #F8EF72);
    box-shadow: rgba(0,0,0,0.3) 0 1px 1px;
    font-family: 'Helvetica Neue',Helvetica, sans-serif;
    /* z-index: 999; */
}
.swiper1 {
  background:aliceblue;
  width: 100%;
  height: 800px;
  position: absolute;
  left:25%;
  top: 40%;
  /* margin-left: -90px; */
  margin-top: -150px;
  /* background-color: aliceblue;  */
}
.swiper1 .swiper-slide img {
  display: block;
  width: 100%;
  background-color: aliceblue;
  height: 800px;
}
.loader2 {
  display: flex;
  width: 11rem;
  height: 11rem;
  top: 170px;
  left: 0%;
  z-index: 5;
}

.curve {
  width: 160%;
  height: 160%;
  position: absolute;
  animation: rotate 8s linear infinite;
  fill: transparent;
}
.blackhole {
  z-index: -1;
  display: flex;
  position: absolute;
  width: 6rem;
  height: 6rem;
  align-items: center;
  justify-content: center;
}
}


  